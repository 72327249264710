<template>
    <v-row>
        <v-col md="2">
            <child-sidebar :children="children" />
        </v-col>
        <v-col md="10">
            <router-view></router-view>
        </v-col>
    </v-row>
</template>
<script>
import ChildSidebar from '@/components/layout/ChildSidebar'

export default {
    name: 'Authentication',
    components: {
        ChildSidebar,
    },
    data: () => ({
        children: [
            {
                title: 'Configurations',
                route: 'authentication-configs',
            },
            {
                title: 'Account Locking',
                route: 'account-locking',
            },
            {
                title: 'Password Complexity',
                route: 'password-complexity',
            },
            {
                title: 'Locked Users',
                route: 'locked-users',
            },
        ],
    }),
}
</script>
